import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '费用单号', prop: 'fpay_no', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '申请日期', prop: 'fpay_date', itemType: 'input', input: false, sortable: false, formatter: val => getDateNoTime(val, true) },
  { label: '收款单位', prop: 'inst_cname', itemType: 'input', input: true, sortable: false },
  {
    label: '费用名称',
    prop: 'fpay_name',
    itemType: 'select',
    options: [
      { value: 1, label: '包干费' },
      { value: 2, label: '测试费' }
    ],
    input: true,
    sortable: false,
    labelWidth: '120px',
    formatter: val => formatFpayName(val)
  },
  { label: '增值税发票号', prop: 'fpay_actu_nos', itemType: 'input', input: false, sortable: false, labelWidth: '160px' },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, labelWidth: '140px' },
  { label: '付款金额', prop: 'fpay_total', itemType: 'input', input: false, sortable: false },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '100px' },
  { label: '汇率', prop: 'ppay_rmbrate', itemType: 'input', input: false, sortable: false, labelWidth: '100px' },
  { label: '本金金额', prop: 'scon_rmbtotal', itemType: 'input', input: false, sortable: false },
  { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, overflowTooltip: true },
  { label: '费用经办人', prop: 'cust_stff_name', itemType: 'input', input: false, sortable: false },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
const formatFpayName = val => {
  if (val === 1) {
    return '包干费';
  } else if (val === 2) {
    return '测试费';
  } else {
    return '暂无';
  }
};
